import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';



const IndexPage = () => {
  // Set up the array of image data and `media` keys.
  // You can have as many entries as you'd like.


  return (<Layout activeLink="home">
            <div className="background--light">
                <div className="wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-24 box--reset-sm">
                                <div className="page-head page-head--reduce">
                                    <h1>Lindan Classic Moments</h1>
                                    <p className="text-intro text-restrict text-default">We're a London based product and service company, offering classic designs and setups, giving you time to relax and enjoy your special day.</p>
                                </div>

                                <div className="banner">
                                    <img className="banner__image" src="https://res.cloudinary.com/dfvhyk2ut/image/upload/f_auto,q_auto,w_2000/v1574605724/lindan-moments/NO.9.jpg" alt="Temp"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
            <div className="wrapper">
                <div className="container">
                    <div className="row">
                        <section className="col-sm-24 box--reset-sm">
                            <div className="background--light">
                                <div className="box box--large text-center text-restrict center">
                                    <p>Our goal is to provide a unique experience for a whole array of different party arrangements.</p>
                    
                                    <p>We'll take care of absolutely everything, whether that be catering, designs, styling, setups, you name it, we'll make it is one less thing for you to have to worry about.</p>
                                    
                                    <p>Please <Link to="/contact">get in touch</Link> with us for a free, no obligation consultation.</p> 
                                    
                                    <div className="button-stack">
                                        <Link to="/what-we-do" className="button button--l button--secondary">Learn what we do</Link>
                                        <Link to="/contact" className="button button--l">Get in touch</Link>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
             </div>
  </Layout>
  );
};

export default IndexPage;